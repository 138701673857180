import React from "react"
import { css } from "@emotion/core"
import { useIntl } from "react-intl"
import { connect } from "react-redux"
import { navigate } from "gatsby"

import {
  Layout,
  SEO,
  Title,
  SectionCenter,
  PrimaryButton,
} from "../../components"
import { getCurrentPath } from "../../redux/actions/pathAction"

const Success = ({ location, getCurrentPath }) => {
  const intl = useIntl()

  React.useEffect(() => {
    getCurrentPath(location.pathname)
  })

  return (
    <Layout bkgColor={"var(--clr-neutral-200)"}>
      <SEO title="Contact form sent" />
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        <Title
          title={intl.formatMessage({ id: "sucess.title" })}
          fontColor="var(--clr-primary-300)"
        />
        <SectionCenter>
          <div
            css={css`
              display: grid;
              row-gap: 1.5rem;
            `}
          >
            <p
              css={css`
                max-width: 700px;
                margin: 0 auto 2rem auto;
                font-size: 1rem;
                font-weight: bold;
                letter-spacing: var(--letter-spacing-sm);
                line-height: 1.75;
              `}
            >
              {intl.formatMessage({ id: "contact.sent" })}
            </p>
          </div>
          <div
            css={css`
              font-size: 5rem;
              margin: 1rem auto;
              text-align: center;
            `}
          >
            🥰
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
              margin: 4rem auto 0 auto;
            `}
          >
            <PrimaryButton
              textSize="0.875rem"
              textTransform="capitalize"
              maxWidth="none"
              margin="0"
              onClick={() => navigate("/")}
            >
              {intl.formatMessage({ id: "btn.return" })}
            </PrimaryButton>
          </div>
        </SectionCenter>
      </section>
    </Layout>
  )
}

export default connect(null, { getCurrentPath })(Success)
